@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap');

/****** common css *******/
.container {
    max-width: 1200px;
}

body {
    background: url('../images/bg-patren.png') repeat-x 0 0 #fff;
    font-size: 14px;
    line-height: 1.5;
    font-family: "Nunito Sans" !important;
    font-weight: 400;
    /* margin: 0 !important;
    padding: 0 !important; */
    /* overflow: unset !important; */
}

.object-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

img.img-size {
    width: 100px !important;
    height: 152px !important;
    padding: 10px !important;
    margin: 0 auto;
}

.text-decoration-line {
    text-decoration: line-through;
}

h1 {
    font-size: 120px;
    font-weight: 600;
    line-height: 132.4px;
    font-family: "Fustat" !important;
}

h3 {
    font-size: 46px;
    font-weight: 700;
    /* line-height: 65.47px; */
}

h4 {
    font-size: 40px;
    font-weight: 700;
    line-height: 26px;
}

h5 {
    font-size: 16px;
    line-height: 26px;
}

h6 {
    font-size: 19px;
    line-height: 32px;
}

.product-cart h6 {
    font-size: 18px;
    line-height: 22px;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

.cursor-pointer {
    cursor: pointer;
}

.price-card {
    font-size: 20px;
}

.image-height-card.overflow-hidden {
    height: 271px;
    border-radius: 20px;
    max-width: 185px;
}

/****** shadow *******/
.shadow-lg {
    box-shadow: 4px 4px 1px #000 !important;
}

.card.myschool {

    animation: shadowAnimation 10s infinite 5s;
}

@keyframes shadowAnimation {

    0%,
    100% {
        box-shadow: 4px 4px 1px #d73d5c;
    }

    50% {
        box-shadow: -4px -4px 1px #d73d5c;
    }
}

section#popular_schl .card .card-body {
    justify-content: start !important;
    text-align: start !important;
}

.banner-search {
    margin-top: -200px;
}

.ti-search {
    font-size: 18px;
}

.shadow-md {
    box-shadow: 2px 2px 1px #000 !important;
}

.shadow-btm {
    box-shadow: 0px 2px 1px #000 !important;
}

/****** color theme *******/
.bg-danger {
    background-color: #D73D5C !important;
}

.btn-danger {
    background-color: #D73D5C !important;
    border-color: #D73D5C !important;
}

.text-danger {
    color: #D73D5C !important;
}

.col-md-6.h-100.eVyapari-content {
    margin: 0 0px 40px 0;
}

/****** zoom-animation *******/
.zoom {
    transition: transform .8s;
}

#search-area .card:hover .zoom {
    transform: scale(1.1);
}

.arrow-img {
    transition: transform .8s;
}

#search-area .card:hover .arrow-img {
    transform: rotate(-30deg);
}

.zoom:hover {
    transform: scale(1.1);
}

/****** btn-animation *******/
.btn-ani {
    position: relative;
    transition: background-color 300ms ease-out;
    padding: 10px 30px !important;
    border-radius: 10px !important;

}

.btn-primary.btn-ani {
    position: relative;
    transition: background-color 300ms ease-out;
    background-color: #D73D5C !important;
    border: #D73D5C !important;
    padding: 10px 30px !important;
    border-bottom: 4px solid #000 !important;

}

.cart-btn:hover {
    color: #000;
    /* -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black; */
    /* box-shadow: 0 8px 6px -6px black; */

}

a.btn.btn-white.border.border-dark.cart-btn.py-1 {
    border-bottom: 4px solid #000 !important;
    border-radius: 10px !important;
    transition: border-bottom 300ms ease-out;
}

a.btn.btn-white.border.border-dark.cart-btn.py-1:hover {
    border-bottom: 1px solid #000 !important;
}

a.btn.btn-danger.border.border-dark.cart-btn.py-1 {
    border-bottom: 4px solid #000 !important;
    border-radius: 10px !important;
    transition: border-bottom 300ms ease-out;
}

a.btn.btn-danger.border.border-dark.cart-btn.py-1:hover {
    border-bottom: 1px solid #000 !important;
}

#steps h6.position-absolute.bottom-0.start-50.translate-middle-x.mb-3.text-white {
    width: 100%;
}

/* a.btn.userprofilebtn {
    border-bottom: 4px solid #000 !important;
    border-radius: 10px !important;
    transition:  border-bottom 300ms ease-out;
}
a.btn.userprofilebtn:hover {
    border-bottom: 1px solid #000  !important;
    padding-bottom: 3px !important;
} */
.marketplace.btn-ani {
    position: relative;
    transition: all 300ms ease-out;
    background-color: #fff !important;
    border-bottom: 4px solid #000 !important;
    color: #000;
    padding: 10px 18px !important;


}

header ul li {
    list-style: none;
}

.btn-ani span {
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
}

.btn-ani:hover span {
    transform: translate3d(-0.6rem, 0, 0);

}

.btn-ani:hover {
    border-bottom: 1px solid #000 !important;

}

.btn-ani .fa-solid {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
}

#product .card.d-flex.border.border-2.p-3.border-dark.shadow-btm.rounded-24.h-100.w-100 {
    height: fit-content !important;
}

#shop-title .border.border-dark.shadow-btm.rounded-3.card.py-4.w-100 {
    border-bottom: 3px solid #000 !important;

}

.btn-ani .fa-solid * {
    stroke-width: 5;
    stroke-color: transparent;
}

.btn-ani:hover .fa-solid {
    opacity: 1;
    right: -1.6rem;
}

.overview-list li a {
    font-size: 18px !important;
    line-height: 24.55px !important;
}

/* ******** Header ****** */
nav.navbar.shadow-4.w-100.top-0.navbar-expand-md.navbar.fixed-top.navbar-light.bg-light {
    background: url('../images/bg-patren.png') !important;
}

.navbar-brand {
    font-size: 34px;
    font-weight: 700;
}

.navbar-expand-md .navbar-nav .nav-link {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
}

/****** Search Area *******/
#search-area .card-body {
    height: 220px;
}

#search-area .card-body.sch-bnr-height {
    height: 358px;
}

#search-area .card-body a img {
    margin: 0 0 6px 0;
}

a.text-decoration-none.position-absolute.bottom-0.start-50.translate-middle-x.d-flex.align-items-end.mb-3 {
    left: 44% !important;
}

a.text-decoration-none.order.position-absolute.bottom-0.start-50.translate-middle-x.d-flex.align-items-end.mb-3 {
    left: 39% !important;
}

button.bg-transparent.border-0.text-decoration-none.position-absolute.bottom-0.start-50.translate-middle-x.d-flex.align-items-end.mb-3 {
    left: 39% !important;
}

a.position-absolute.place.bottom-0.start-50.translate-middle-x.d-flex.align-items-end.mb-3 {
    left: 50% !important;
}

#search-area h5 {
    font-size: 20px;
}

#search-area h2 {
    font-size: 40px;
}

/****** Steps Area *******/
#steps .card {
    transition: all 600ms ease-out;
}

#steps .card:hover {
    margin-top: -20px;
}

#steps .card-body {
    height: 260px;
}

img.d-block.img-fluid.mx-auto.rounded-4.h-100 {
    /* height: 668px !important; */
    object-fit: contain;
}

.dark.shadow-btm.rounded-4.h-100 {
    border-radius: 24px !important;
}

.side-width {
    width: 20%;
    min-width: 110px;
}

.carousel {
    overflow-y: scroll;
    max-height: 700px;
    scrollbar-width: none;
}

div#product h3 {
    font-size: 18px;
}

div#product p {
    font-size: 16px !important;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: 0 solid transparent !important;
    border-bottom: 0;
    border-left: 0 solid transparent !important;
}

.dropdown.medium .btn {
    width: 185px !important;
    height: 40px !important;
}

.rounded-24 {
    border-radius: 24px !important;
}

.input-search {
    width: 404px;
    height: 50px;
}

a.btn.btn-light.shadow-btm.px-4.wishlist.rounded-2 {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Media tags Start */

img.d-block.h-100.img.card-image.img-fluid.mx-auto.rounded-5 {
    height: 245px !important;
}

/*******************************Properties css*******************************************/

#properties .text-b {
    font-size: 20px;
    line-height: 27.28px;
}

#properties .text-n {
    font-size: 20px;
    font-weight: 500;
    line-height: 27.28px;
}

/*******************************bottom-on-products*******************************************/
#bottom-on-products .product-bold {
    font-size: 18px;
    line-height: 24.55px;
}

#bottom-on-products .product-normal {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.55px;
}

#bottom-on-products .detail-same {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.82px;
    text-align: left;
}

#bottom-on-products .content {
    font-size: 18px;
    line-height: 24.55px;
}

@media only screen and (max-width: 1000px) {
    h1 {
        font-size: 70px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 1068px) {
    h1 {
        font-size: 70px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 575px) {
    .input-search {
        width: unset;
        height: 50px;
    }

    .dropdown.medium .btn {
        width: 100% !important;
        height: 40px !important;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 50px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .col-md-6.h-100.eVyapari-content {
        margin: 0 0px 0px 0;
    }

    .side-width {
        width: unset;
    }

    .carousel {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none;
    }

    /* #product .card.d-flex.border.border-2.p-3.border-dark.shadow-btm.rounded-24.h-100.w-100{max-height: 285px;} */
    .carousel .item1 {
        flex: 0 0 auto;
        margin-right: 10px;
        order: 2;
        width: 120px;
    }

    .carousel img {
        height: auto;
    }
}

/* @media only screen and (max-width: 768px) {
.py-5 {padding-top: 0px !important; padding-bottom: 3rem !important;}} */
@media only screen and (max-width: 768px) {
    p {
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 768px) {
    h4 {
        font-size: 25px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .card-body {
        font-size: 16px;
        text-align: center;
    }
}


/* foooter---------------------------------- */

.shadow-md {
    box-shadow: 2px 2px 2px #000;
}

/*******************************footer section*******************************************/

#footer .input-group {
    max-width: 465px;
    margin-left: 0px;
    width: 90%;
    color: #A4A4A5;
}

#footer a {
    text-decoration: none;
    color: #2D2D2D;
}

#refund_and_returns .modal-content {
    border-radius: 30px;
}

#refund_and_returns .modal-body {
    padding: 25px;
}

#disclaimer .modal-content {
    border-radius: 30px;
}

#disclaimer .modal-body {
    padding: 25px;
}

#privacy_and_policy .modal-content {
    border-radius: 30px;
}

#privacy_and_policy .modal-body {
    padding: 25px;
}

#ReadMoreModal .modal-content {
    border-radius: 30px;
}

#ReadMoreModal .modal-body {
    padding: 25px;
}


#footer h6 {
    font-size: 17px;
}

#footer .text-top {
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.04em;
}

#footer h2 {
    font-family: Fustat;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
}

#footer h5 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.04em;
    font-weight: 700;
}

#footer li {
    font-size: 14px;
    font-weight: 500;
    color: #777679;
}


/* Media tags End */

.cart-animation.position-absolute.top-0.end-0.bg-danger.rounded-circle {
    width: 23px;
    height: 23px;
    color: #fff;
    top: -10px !important;
    right: -15px !important;
}

.custom-arrow {
    font-size: 24px;
    /* Adjust size as needed */
    color: black;
    /* Change color */
    cursor: pointer;
    z-index: 100;
    /* Ensure arrows are above the slider */
    transition: color 0.3s;
    /* Smooth color transition */
}

.custom-arrow:hover {
    color: gray;
    /* Change color on hover */
}

.prev-arrow {
    left: 10px;
    /* Adjust position */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* Center vertically */
}

button.slick-arrow.slick-next .button.slick-arrow.slick-prev {
    background-color: #000;
    border-radius: 50%;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 24px;
    line-height: 1;
    opacity: .75;
    color: #000 !important;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.next-arrow {
    right: 10px;
    /* Adjust position */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* Center vertically */
}

.slick-slider.slick-initialized .item .card.border-dark.shadow-btm.rounded-4.h-100.p-4 {

    border-top: 1px solid #fff;
    border-right: 5px solid #fff;
    border-bottom: 5px solid #fff;
    border-left: 1px solid #fff;
    cursor: pointer;
    transition: border 0.3s ease;

}

.slick-slider.slick-initialized .item .card.border-dark.shadow-btm.rounded-4.h-100.p-4:hover {
    /* border: 5px solid #000; */
    border-top: 1px solid #000;
    /* Add a solid black border on hover */
    border-right: 5px solid #000;
    /* Add a solid black border on hover */
    border-bottom: 5px solid #000;
    /* Add a solid black border on hover */
    border-left: 1px solid #000;
    /* Add a solid black border on hover */
    cursor: pointer;
    /* Change cursor to pointer */
    transition: border 0.3s ease;
    /* Smooth transition for the border */
}

/* Optional: if you want to change background color or other styles on hover */
/* .slick-slider.slick-initialized .item .card.border-dark.shadow-btm.rounded-4.h-100.p-4:hover {
    background-color: #f8f9fa; 
} */


/*******************************Cart css*******************************************/
#cart-top h5 {
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}

#cart-top .wishlist {
    padding: 13px 8px 13px 8px;
    gap: 10px;
    border-radius: 10px 0px 0px 0px;
    border: 1px 1px 3px 1px;
}

#cart-top .cart-btn {
    padding: 13px 10px 13px 100px;
    gap: 10px;
}

.font-cart {
    font-size: 18px;
    font-weight: 600;
}

.form-control {
    color: #0E0E0E !important;
    font-weight: 600;
}

#cart-item-list-o h5 {
    font-size: 26px;
    font-weight: 700;
    line-height: 21.82px;
}

#cart-item-list-o p {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

#cart-item-list-o price {
    font-size: 26px;
    font-weight: 700;
    line-height: 32.74px;
}


/*******************************Cart-item css*****************************************/

#cart-item-list h5 {
    font-size: 26px;
    font-weight: 700;
    line-height: 21.82px;
}

#cart-item-list p {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.37px;
}

#cart-item-list price {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
}

#cart-item-list checkout-card {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
}

#cart-item-list books {
    font-size: 14px;
    font-weight: 600;
}

.btn-font-size {
    font-size: 20px;
    font-weight: 500;
    border: 1px solid #000 !important;
}

@media (min-width:768px) {
    #cart-item-list .list-area {
        width: 652px;
    }

    #cart-item-list .bill-details {
        width: 464px;
    }
}

@media (min-width:768px) and (max-width:1199.98px) {
    #cart-item-list .bill-details {
        width: 304px;
    }
}

@media (min-width:768px) and (max-width:991.98px) {
    #cart-item-list .list-area {
        width: 420px;
    }
}

/* User login/register css */
.user h3 {
    font-size: 28px;
    font-weight: 700;
}

.user h4 {
    font-size: 26px;
    font-weight: 600;
}

.user h5 {
    font-size: 20px;
    font-weight: 500;
}

.user h6 {
    font-size: 16px;
    font-weight: 500;
}

.user .form-group input {
    font-size: 14px;
    font-weight: 400;
    color: #6B7888 !important;
}

.user .form-group input {
    font-size: 16px;
    font-weight: 400;
    color: #6B7888 !important;
}

.user .form-group select {
    font-size: 16px;
    font-weight: 400;
    color: #6B7888 !important;
}

/* checkout */
.checkout h4 {
    font-size: 30px;
    font-weight: 600;
}

.checkout h5 {
    font-size: 28px;
    font-weight: 700;
}

.checkout h6 {
    font-size: 20px;
    font-weight: 600;
}


/* Media tags Start */

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 50px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 768px) {
    p {
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 768px) {
    h4 {
        font-size: 25px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .card-body {
        font-size: 16px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .price {
        font-size: 16px;
        text-align: start;
        margin-top: 5px;
    }
}

/* ****** Product page media Tags */

@media only screen and (max-width: 768px) {
    .grid-container {
        display: inline-block;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .item1 {
        display: inline-block;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .item3 {
        text-align: center;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .button-size {
        text-align: center;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .set-res {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .ms-size {
        margin-left: 8px !important;
    }
}

@media only screen and (max-width: 768px) {
    .btn-fit {
        padding-left: 0px !important;
        margin-top: 15px;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 768px) {


    h3.wishtext {
        font-size: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .card-body {
        text-align: center !important;
        justify-content: center;
    }
}

@media (min-width:768px) and (max-width:1199.98px) {
    #cart-item-list .bill-details {
        width: 304px;
    }
}

@media (min-width:768px) {
    #cart-item-list .list-area {
        width: 652px;
    }

    #cart-item-list .bill-details {
        width: 464px;
    }
}

@media (min-width:768px) and (max-width:991.98px) {
    #cart-item-list .list-area {
        width: 420px;
    }
}




.dashed {
    border-style: dashed;
    width: 286.8px;
    height: 266px;
}

.abc {
    margin-left: 350px !important;
}



@media only screen and (max-width: 768px) {
    .info-deatil {
        align-items: center;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .dashed {
        border-style: dashed;
        width: 270px;
        height: 266px;
    }
}

@media only screen and (max-width: 768px) {
    .card-body {
        text-align: center !important;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .ab {
        border-style: dashed;
        width: 270px;
        height: 266px;
    }
}

/* ----------------------cart------------------ */
.rounded-3.border.border-2.border-dark.px-2.order-btn {
    padding: 8px;
}

.product-text-section {
    max-width: 213px;
    width: 100%;
}


@media only screen and (max-width: 768px) {
    .product-text-section {
        max-width: unset;
        width: unset;
    }
}

/*******************************Cart css*******************************************/
#cart-top h5 {
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}

#cart-top .wishlist {
    padding: 13px 8px 13px 8px;
    gap: 10px;
    border-radius: 10px 0px 0px 0px;
    border: 1px 1px 3px 1px;
}

#cart-top .cart-btn {
    padding: 13px 10px 13px 100px;
    gap: 10px;
}

.font-cart {
    font-size: 18px;
    font-weight: 600;
}

.form-control {
    color: #0E0E0E !important;
    font-weight: 600;
}

#cart-item-list h4 {
    font-size: 24px;
    font-weight: 600;
}

#cart-item-list-o h5 {
    font-size: 26px;
    font-weight: 700;
    line-height: 21.82px;
}

#cart-item-list-o p {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

#cart-item-list-o price {
    font-size: 26px;
    font-weight: 700;
    line-height: 32.74px;
}

/*******************************Cart-item css*****************************************/

#cart-item-list h5 {
    font-size: 26px;
    font-weight: 700;
    line-height: 21.82px;
}

#cart-item-list h6 {
    font-size: 18px;
    font-weight: 600;
}

#cart-item-list p {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.37px;
}

#cart-item-list price {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
}

#cart-item-list checkout-card {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
}

#cart-item-list books {
    font-size: 14px;
    font-weight: 600;
}

.btn-font-size {
    font-size: 20px;
    font-weight: 500;
    border: 1px solid #000 !important;
}

/* -------------------best selller card------------- */
#best-seller .best-seller-card-container {
    max-width: 145.27px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#best-seller h6 {
    font-size: 32px;
}

#best-seller .best-seller-card-container img {
    width: 100%;
}

#best-seller .vr {
    width: 1px !important;
    opacity: 1.5 !important;
}

#best-seller .price {
    font-size: 32px;
}


/* *******************************  school page   ******************* */
#myschool .school-img {
    border-radius: 20px 0 0 20px;
}

#myschool h3 {
    font-size: 28px;
    font-weight: 600;
}

#myschool h4 {
    font-size: 24px;
    font-weight: 700;
}

#myschool h5 {
    font-size: 20px;
    font-weight: 500;
}

#myschool h6 {
    font-size: 18px;
    font-weight: 500;
}

#myschool .category {
    width: fit-content;
}

#previewModal .modal-header {
    border-radius: 30px 30px 0 0;
}

#previewModal .modal-content {
    border-radius: 30px;
}

@media only screen and (max-width: 768px) {
    .school-img {
        height: -webkit-fill-available;
    }
}

.form-check-input:checked {
    background-color: #d73d5c;
    /* Background color when checked */
    border-color: #d73d5c;
    /* Border color when checked */
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    /* Optional: Focus ring */
}

/* ********************************* My Account Page************************************ */

#myprofile h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
}

#myprofile p {
    font-size: 16px;
}

#account-details h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
}

#account-details p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
}

#account-details price {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
}

#account-details checkout-card {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
}

.border-section {
    border: 2px dashed #D73D5C;
    width: 286px;
}

.card.order-review.border.border-dark.shadow-btm.rounded-3 {
    max-width: 467px;
}

.place-order-table {
    width: 100%;
}

table.place-order-table,
.place-order-table th,
.place-order-table td {
    border: 1px solid black;
    /* border-collapse: collapse; */
    padding-left: 10px;
    border-radius: 10px;
}

table.place-order-table thead,
table.place-order-table th {
    font-size: 20px;
}

table.place-order-table tbody th {
    text-align: center;
}

.place-order-table-div {
    border: 1px solid;
}

/*******************************personal-info head section*******************************************/

.my-acccount h4 {
    font-size: 30px;
    font-weight: 600;
    line-height: 26px !important;
}

.onlinepayment:checked {
    background-color: #d73d5c;
    border-color: #d73d5c !important;
}

#cart .onlinepayment {
    height: 1.2rem;
    width: 1.2rem;
}

.onlinepayment:checked[type=radio] {
    --bs-form-check-bg-image: url(../images/checkbox.svg);
}

/*******************************personal-info section***************************************/

#my-personal-info .information {
    width: 42% !important;
}

#my-personal-info .bill-details {
    width: 52% !important;
}

#my-personal-info h6 {
    font-size: 24px;
    line-height: 33.6px;
}

#my-personal-info .font-cart {
    font-size: 28px;
    line-height: 39.2px;
}

#my-personal-info .form-label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

#my-personal-info ::placeholder {
    color: #000000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

/******************************* My Address Page***********************************/
#my-address .information {
    width: 42% !important;
}

#my-address .bill-details {
    width: 52% !important;
}

#my-address h5 {
    font-size: 30px;
    font-weight: 600;
    line-height: 26px;
}

#my-address h6 {
    font-size: 24px;
    font-weight: 700;
}

#my-address .edit {
    padding-top: 80px;
}

/*******************************add-new-address-form*******************************************/
#add-new-address-form .information {
    width: 42% !important;
}

#add-new-address-form .bill-details {
    width: 52% !important;
}

#add-new-address-form h6 {
    font-size: 24px;
    line-height: 33.6px;
}

#add-new-address-form .font-cart {
    font-size: 28px;
    line-height: 39.2px;
}

#add-new-address-form .form-label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

#add-new-address-form ::placeholder {
    color: #000000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

/*******************************change-password*******************************************/
#change-password .information {
    width: 42% !important;
}

#change-password .bill-details {
    width: 52% !important;
}

#change-password h6 {
    font-size: 24px;
    line-height: 33.6px;
}

#change-password .font-cart {
    font-size: 28px;
    line-height: 39.2px;
}

#change-password label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

#change-password ::placeholder {
    color: #000000;
}


/* *******************************  Order-History   ******************* */
#order-detail .information {
    width: 42% !important;
}

#order-detail .bill-details {
    width: 57% !important;
}

#order-detail .ord-dtl {
    padding-top: 0px;
}

#order-detail .short {
    font-size: 12px;
    font-weight: 500;
}

#order-detail h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

#order-detail h6 {
    font-size: 24px;
    font-weight: 700;
}

#order-detail .ord-tit {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

#order-detail .ord-opt {
    font-size: 16px;
    font-weight: 700;
}


/* *******************************  Order-History-cancelled ******************* */
#order-detail-cancelled .information {
    width: 42% !important;
}

#order-detail-cancelled .bill-details {
    width: 57% !important;
}

#order-detail-cancelled .ord-dtl {
    padding-top: 0px;
}

#order-detail-cancelled .short {
    font-size: 12px;
    font-weight: 500;
}

#order-detail-cancelled h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

#order-detail-cancelled h6 {
    font-size: 24px;
    font-weight: 700;
}

#order-detail-cancelled .ord-tit {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

#order-detail-cancelled .ord-opt {
    font-size: 16px;
    font-weight: 700;
}


/* *******************************  popular school page   ******************* */
#popular_schl h4 {
    font-size: 24px;
    font-weight: 400;
}

#popular_schl .p {
    font-size: 16px;
    font-weight: 700;
}


/* Media tags Start */

@media only screen and (max-width: 768px) {
    #add-new-address-form .bill-details {
        width: 100% !important;
    }

    h1 {
        font-size: 50px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 768px) {
    p {
        font-size: 15px;
        text-align: start;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 768px) {
    h4 {
        font-size: 25px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .card-body {
        font-size: 16px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    #best-seller .price {
        font-size: 16px;
        text-align: center;
        margin-top: 5px;
    }

    #best-seller .row.cartrow .col-md-12>div,
    #best-seller .row.cartrow .col-md-12>div .btn {
        margin: 0 !important;
        font-size: 90%;
    }

    #best-seller .row.cartrow .col-md-12>div .btn {
        padding: 8px 5px;
        margin: 0 !important;
        font-size: 90%;
    }

    #best-seller .row.cartrow .col-md-12>div .btn i {
        margin-right: 2px !important;
        font-size: 90%;
    }

    #best-seller .row.cartrow .col-md-12.order-md-2.order-1 {
        margin: 0 !important;
        padding: 8px 2px;
    }

    #best-seller .row.cartrow .col-md-12.col-sm-6.col-5.order-md-1.order-2 {
        margin: 0 !important;
        padding: 8px 2px;
    }

    #best-seller ul.list-inline {
        margin: 0 !important;
    }

    #best-seller ul.list-inline>li {
        margin-right: 2px !important;
        font-size: 12px;
    }

}

#bottom_navigation_bar {
    display: none;
}

.footicon {
    /* color: #d73d5c !important; */
}

.footicon {
    font-size: 1.5rem;
}



@media (min-width:280px) and (max-width:768px) {
    #bottom_navigation_bar {
        display: block;
    }

    #my-address .information {
        width: unset !important;
    }

    #my-address .bill-details {
        width: unset !important;
    }
}

@media (min-width:280px) and (max-width:815px) {
    .sendotp {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

ul.dropdown-menu.listicat.p-3.show {
    left: unset !important;
    right: 0 !important;
}

.poition-fixed.fixed-bottom.mobile-navbar {
    bottom: 10px !important;
}

.bottom-nav li {
    list-style: none;
    flex: 1 !important;
}

.shadow-navbar {
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
    border: 1px solid #000;
    border-bottom: 3px solid #000;
    /* overflow: hidden; */
}

.school-mobile.rounded-pill {
    height: 50px;
    width: 50px;
    top: 0;
    left: -20px;
    right: 0;
    transform: translate(50%, -50%);
}

.school-mobile.rounded-pill>i {
    font-size: 1.5rem;

    animation: fontSizeChange 1s infinite alternate;
}

@keyframes fontSizeChange {
    0% {}

    100% {
        /* font-size: 1.6rem; */
    }
}

/* ------------------product card --------------------- */

.product-cart .card-footer .d-flex {
    column-gap: 10px !important;


}

.product-cart .image-div {
    border-radius: 20px !important;
    height: 245px !important;
    width: 100% !important;
    margin: 10px auto;
}

.product-cart .wishlist-btn {
    border-radius: 10px;
}

@media screen and (max-width:767px) {
    .icon {
        width: 20px;
    }

    #my-personal-info .bill-details {
        width: 100% !important;
    }

    #change-password .bill-details {
        width: 100% !important;
    }

    #order-detail .bill-details {
        width: 100% !important;
    }

    .overflow-hidden.rounded-4.h-md-100.w-50 {
        width: 100% !important;
    }

    .banner-search {
        margin-top: 0;
    }

    .buy {

        background-color: #D73D5C;

        border-color: #D73D5C;

    }

    .dropdown-menu-end {

        margin-right: 30px;

    }

    .banner-search button {
        padding: 5px !important;
        margin-left: 5px !important;
        background-color: #fff !important;
        color: #000 !important;
        /* display: none; */

    }

    .banner-search input,
    .banner-search select {
        padding: 8px !important;
        font-size: 12px;
        border-radius: 7px !important;
    }

    .marketplace .banner_img_contain {
        display: none;
    }

    .navbar-drop {
        border: 0 !important;
    }


    .card-body.h-md-100.w-50 {
        width: 100% !important;
    }

    .poition-fixed.fixed-bottom.mobile-navbar.d-block.d-md-none {
        z-index: 11 !important;
    }

    .schoolbookset h3 {
        font-size: 18px !important;
        line-height: unset;
    }

    .schoolbookset img {
        max-height: 100px;
    }

    .discriptiontext span * {
        margin-bottom: 5px;
    }

    .schoolbookset h6 {
        font-size: 12px !important;
        line-height: unset;
    }

    .schoolbookset h5 {
        font-size: 12px !important;
        line-height: unset;
    }

    .schoolbookset .school-set-price {
        font-size: 16px !important;
        line-height: unset;
    }

    .eVyapari-content {
        position: fixed;
        top: 54px;
        background: url('../images/bg-patren.png') repeat-x 0 0 #fff;
        z-index: 5;
        max-height: 135px;
        /* height: 100% !important; */
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    }

    .space-top {
        margin-top: 100px !important;
    }

    #search-area {
        margin-top: 60px !important;
    }

    .overflow-hidden.schoolimg.rounded-4.h-100.w-50 {
        max-height: 300px;
    }

    .product-cart {
        min-width: 160px;
        width: 100%;
        padding: 8px !important;
        border-radius: 12px !important;
        border: 1px solid #000 !important;
        border-bottom: 3px solid #000 !important;
    }

    .product-cart span.d-flex.align-items-start.justify-content-start.text-start.ms-0 ul {
        margin: 0 !important;
        padding: 0 !important;
    }

    .product-cart span.d-flex.align-items-start.justify-content-start.text-start.ms-0 ul li {
        list-style: none;
    }

    .image-height-card.overflow-hidden {
        height: unset;
        border-radius: 20px;
        max-width: 185px;
    }

    .product-cart .image-div {
        /* padding: 10px 25px !important; */
        border-radius: 10px !important;
        max-width: 107px !important;
        height: 117px !important;
        width: 100% !important;
        margin: 10px auto;
    }

    .product-cart img {
        border-radius: 10px !important;
    }

    .product-cart h6 {
        font-size: 14px !important;
        margin: 0 !important;
        line-height: 18px;
    }

    .product-cart p {
        font-size: 10px !important;
        margin: 0 !important;
    }

    .product-cart .dis-price {
        font-weight: 600;
    }

    .product-cart .btn {
        font-size: 12px !important;
        margin: 0 !important;
        color: #FCFCFC !important;
    }

    .product-cart .btn.buynow {
        background-color: #D73D5C !important;
        padding: 4px 8px !important;
        text-align: center !important;
        border-radius: 8px !important;
        /* margin-left: 5px !important; */

    }

    .price-card {
        font-size: 15px;
    }

    .prod {

        flex-shrink: 0;

        width: 100%;

        max-width: 100%;

        padding-right: 0;

        padding-left: calc(var(--bs-gutter-x)* .5);

        margin-top: var(--bs-gutter-y);

    }

    .product-cart .image-div {
        /* padding: 10px 25px !important; */
        border-radius: 10px !important;
        max-width: 107px !important;
        height: 117px !important;
        width: 100% !important;
        margin: 10px auto;
    }

    .product-cart img {
        border-radius: 10px !important;
    }

    .product-cart h6 {
        font-size: 14px !important;
        margin: 0 !important;
        line-height: 18px;
    }

    .image-height-card.overflow-hidden {
        height: 117px;
        border-radius: 20px;
        max-width: 110px;
    }

    .product-cart p {
        font-size: 10px !important;
        margin: 0 !important;
    }

    .product-cart .dis-price {
        font-weight: 600;
    }

    .product-cart .btn {
        font-size: 12px !important;
        margin: 0 !important;
        color: #FCFCFC !important;
    }

    .product-cart .btn.buynow {
        background-color: #D73D5C !important;
        padding: 4px 8px !important;
        text-align: center !important;
        border-radius: 8px !important;
        /* margin-left: 5px !important; */

    }

    .product-cart .card-footer .d-flex {
        column-gap: 2px !important;
        padding: 3px 2px !important;

    }

    .product-cart ul li {
        font-size: 13px !important;
        margin-right: 2px !important;

    }

    .product-cart ul {
        margin: 8px auto;

    }

    #best-seller .card-body.w-50.align-items-center.justify-content-start.d-flex {

        width: 75% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #best-seller .card h6 {
        font-size: 110%;
        line-height: 19.1px;
        margin: 0;
    }

    #best-seller .card p {
        font-size: 12px;
        margin-bottom: 0 !important;
    }

    #best-seller .card .card-body {
        padding: 10px 5px !important;
    }

    #search-area button.w-100.btn.bg-danger.text-white.rounded-2.border-dark.shadow-md.fw-bold {
        font-size: 14px;
    }

    #search-area button.w-100.btn.text-dark.rounded-2.border-dark.shadow-md.fw-bold {
        font-size: 14px;
    }

    .proceedcheckoutbtn {
        position: fixed;
        bottom: 90px;
    }
}

.blink-shadow-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    /* background-color: #4CAF50; */
    background-color: #008000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 800;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: box-shadow 1s ease-in-out; 
    animation: blink-shadow 1s infinite; 
  }
  
  @keyframes blink-shadow {
    0% {
      box-shadow: 0 0px 40px rgba(0, 255, 0, 0.1); /* Green glowing shadow */
    }
    50% {
      box-shadow: 0 0 35px rgba(0, 255, 0, 0.5); /* Brighter shadow */
    }
    100% {
      box-shadow: 0 0 40px rgba(0, 255, 0, 0.1); /* Back to original shadow */
    }
  }
  
  .blink-shadow-button:hover {
    background-color: #026702; 
    /* box-shadow: 0 0 15px rgba(0, 255, 0, 0.8); */
  }

  .checkoutbtn-success {
    background-color: rgb(48,104,68);
  }
  

.mobile-cart-animation.position-absolute.top-0.end-0.bg-danger.rounded-circle {
    width: 20px;
    height: 20px;
    color: #fff;
    top: -15px !important;
    right: -10px !important;
}

/* .mobile-school::before {
    content: '';
    height: 75px;
    position: absolute;
    background: #fff;
    box-shadow: 0px 4px 40px rgb(0, 0, 0, 0.25);
    width: 75px;
    border-radius: 50%;
    z-index: 1;
    right: 50%;
    top: 13px;
    bottom: 10px;
    animation: pulse 1.5s infinite;
    transform: translate(50%, -50%);
} */

/* @keyframes pulse {

    0%,
    100% {
        box-shadow: 0px 4px 40px rgb(0, 0, 0, 0.25);
    }

    50% {
        box-shadow: 0 0 20px rgba(255, 0, 0, 0.6);
    }
}

@keyframes pulse2 {

    0%,
    100% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
    }

    50% {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    }
} */


#popup #myschool_btn {
    position: fixed;
    bottom: 10%;
    right: 10px;
    z-index: 10;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    padding: 5px;
    font-size: 20px;
}

#popup #myschool_btn::before {
    position: fixed;
    content: '';
    bottom: 10%;
    right: 10px;
    z-index: 10;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.5);
}

#popup #myschool_btn::after {
    position: fixed;
    content: '';
    bottom: 10%;
    right: 10px;
    z-index: 10;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 40px rgba(255, 0, 0, 0.5);

    /* animation: rotate 2s linear infinite; */
}

input::placeholder {
    color: rgb(146, 146, 146) !important;
    /* Change to your desired color */
    opacity: 1;
    /* Optional: to control opacity */
}

.market-btn:hover {
    background: #d73d5c;
    color: #fff;
    border-color: #d73d5c !important;
}

/* button.marketplace-card::before {
    content: '';
    background: #d73d5c;
    width: 200px;
    height: 200px;
    position: absolute;
} */
.market-btn {
    transition: all 300ms ease-out;

}

.market-btn.active:hover {
    background: #fff;
    color: #fff;
}

.market-btn.active {
    transition: all 300ms ease-out;

}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        box-shadow: 0 1px 10px rgba(255, 0, 0);
    }

    100% {
        transform: rotate(360deg);
        box-shadow: 0 1px 10px rgba(0, 0, 0);
    }
}

.mobile-school.active::before {

    background: #d73d5c;
    animation: pulse2 1.5s infinite;
}

.offcanvas .offcanvas-body .dropdown-item {

    white-space: normal;

}

.mobile-school>* {
    position: relative;
    z-index: 1;
    top: -10px;
    /* transform: translate(50%, 50%); */
    left: 0;
}

.order-detail-icons {
    color: #455A64;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 280px;
}

.border-bottom-3 {
    border-bottom: 4px solid #000 !important;
}

.border-bottom-3.active {
    border-bottom: 3px solid #D73D5C !important;
}

.border.border-dark.rounded-3.mt-3.d-flex.border-bottom-3.flex-wrap.position-relative button.position-absolute.end-0.top-0.btn.btn-sm.btn-dark.p-0.px-1 {
    right: 10px !important;
    top: 5px !important;
}

.d-flex.border.border-2.mt-3.border-dark.rounded-3.align-items-center.position-relative button.position-absolute.end-0.top-0.btn.btn-sm.btn-dark.p-0.px-1 {
    right: 10px !important;
    top: 5px !important;
}

.d-flex.h-100.align-items-center.justify-content-between.flex-column.align-items-center.border.border-bottom-3.rounded-12 {
    max-width: 182px;
    z-index: 5;
    background: #fff;

}

.d-flex.h-100.align-items-center.justify-content-between.flex-column.align-items-center.border.border-bottom-3.rounded-12>* {
    z-index: 5;

}

.rounded-12 {
    border-radius: 12px !important;
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 20%;
    float: left;
    position: relative;
    font-weight: 400;
}


/*Icon progressbar*/
#progressbar {
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 20%;
    float: left;
    position: relative;
    font-weight: 400;
}

#progressbar .step0:before {
    font-family: FontAwesome;
    content: "\f10c";
    color: #fff;
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px;
}

/*ProgressBar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%;
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after,
#progressbar li:nth-child(4):after {
    left: -50%;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%;
}

/* #progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
} */

/*Color number of the step and the connector before it*/
#progressbar li.active:before,
#progressbar li.active:after {
    background: #d73d5c;
}

#progressbar li.active:before {
    font-family: FontAwesome;
    content: "✓";
}

.top {
    padding-inline: 4% !important;
}



@media screen and (min-width: 768px) {
    .top {
        padding-inline: 7% !important;
        /* padding-right: 8% !important; */
    }

    .icon {
        width: 30px;
    }
}
ul.list.list-tabs .active
{
    color: #D73D5C !important;
}
.slick-slide.slick-active > div
{
    width: 100%;
}
.discription-tag p{
     margin-bottom: 0;
}
@media screen and (max-width:575px) {
    .mobile-navbar {
        font-size: 13px;
    }

    #myprofile h5 {
        font-size: 17px;
        font-weight: 700;
        line-height: unset;
    }

    #bottom-on-products .product-normal * {
        font-size: 14px;
        font-weight: 400;
        line-height: 24.55px;
    }

    #bottom-on-products .product-normal ul {
        padding-left: 0;
    }

    section#popular_schl .input-group.w-50.mx-auto.border.border-dark.rounded-3.shadow-md,
    section#popular_schl .input-group.border.border-dark.rounded-3.shadow-md.w-25 {
        width: 100% !important;
    }

    section#popular_schl .card .card-body {
        justify-content: start !important;
        text-align: start !important;
    }

    section#popular_schl .card .card-body h4 {
        justify-content: start !important;
        text-align: start !important;
    }

    #properties .text-b {
        font-size: 18px;
        line-height: 27.28px;
    }

    ul.list.list-tabs {
        padding-left: 0 !important;
    }

    #myprofile p {
        font-size: 13px;
        margin-bottom: 0;
    }

    #properties .text-n {
        font-size: 16px;
        font-weight: 500;
        line-height: 27.28px;
    }

    #myschool h4 {
        font-size: 12px;
        text-align: left;
        line-height: unset;
    }

    #myschool h5 {
        font-size: 12px;
        text-align: left;
        line-height: unset;
    }

    .checkout h6 {
        font-size: 16px;
    }

    .checkout h4 {
        font-size: 22px;
    }

    .checkout h5 {
        font-size: 19px;
    }

    .checkout div {
        font-size: 14px;
    }

    .checkout .btn {
        font-size: 14px;
    }

    #best-seller .card h6 {
        font-size: 14px;
        line-height: 19.1px;
        margin: 0;
    }

    table.place-order-table thead,
    table.place-order-table th {
        font-size: 13px;
    }

    table.place-order-table thead,
    table.place-order-table tr {
        font-size: 10px;
    }

    table.place-order-table,
    .place-order-table th,
    .place-order-table td {
        border: 1px solid black;
        /* border-collapse: collapse; */
        padding-left: 5px;
        border-radius: 10px;
    }

    .bill-drop {
        font-size: 12px !important;
    }

    .book-set-class h4,
    .book-set-class .btn {
        font-size: 16px !important;
    }

    section#shop-title h2 {
        font-size: 18px;
        margin-bottom: 0;
    }

    section#shop-title .bg h2 {
        font-size: 14px;
        margin-bottom: 0;
    }

    section#shop-title .btn {
        font-size: 10px;
    }

    section#shop-title {
        font-size: 12px;
    }

    .d-flex.border.border-2.border-dark.rounded-3.mt-3.align-items-center .btn {
        font-size: 12px;
    }

    .d-flex.border.border-2.mt-3.border-dark.rounded-3.align-items-center.position-relative,
    .d-flex.border.border-2.mt-3.border-dark.rounded-3.align-items-center.position-relative h5 {
        font-size: 12px !important;
        margin-bottom: 0;
    }

    .border.border-dark.rounded-3.mt-3.d-flex.border-bottom-3.flex-wrap.position-relative .btn {
        font-size: 12px !important;
        margin-bottom: 0;
    }

    .d-flex.h-100.align-items-center.justify-content-between.flex-column.align-items-center.border.border-bottom-3.rounded-12 {
        max-width: 100px;
    }

    .d-flex.h-100.align-items-center.justify-content-between.flex-column.align-items-center.border.border-bottom-3.rounded-12 .nav-link {
        font-size: 10px;
    }

    #best-seller .row.cartrow .col-md-12>div,
    #best-seller .row.cartrow .col-md-12>div .btn {
        margin: 0 !important;
        font-size: 12px;
    }
}

ul.dropdown-menu.py-3.navbar-drop.p-md-3.show {
    width: calc(100% - 20px);
    margin: 0 auto;
    border: 2px solid #000;
    border-radius: 20px;
}

.title .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 10px;
}

.dropdown-center.title {
    /* position: relative; */
    position: static;
}



.profile.dropdown-menu[data-bs-popper] {

    left: unset;
    right: 0;

}


/* vendor new cards */
#books-show h5 {
    font-size: 18px;
    font-weight: 600;
}

#books-show h6 {
    font-size: 20px;
}

#books-show .gap-5 {
    gap: 71px !important;
}

#classes h5 {
    font-size: 18px;
    font-weight: 600;
}

#classes h6 {
    font-size: 20px;
}

#classes .gap-5 {
    gap: 71px !important;
}

/* .overflow-hidden.schoolimg.rounded-4.h-100.w-50 {
    max-height: 300px;
} */

.top-catagory-card-img {
    max-width: 132px;
    max-height: 132px;
}

#shop-title .card {
    position: relative;
    /* max-width: 200px; */
    width: 100%;
    max-height: 190px;
    height: 190px;
    border-radius: 14px;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff; */
    box-shadow: 0 4px 30px rgb(0, 0, 0, 0.25);

}

#shop-title .bg {
    position: absolute;
    top: 5px;
    left: 5px;
    /* max-width: 190px; */
    width: calc(100% - 10px);
    height: 180px;
    font-size: 20px;
    z-index: 2;
    background: rgba(255, 255, 255, .95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
}

#shop-title .blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ff0000;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
}

img.categorycardimg {
    object-fit: contain;
}

@keyframes blob-bounce {
    0% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }

    25% {
        transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }

    50% {
        transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }

    75% {
        transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }

    100% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
}

#shop-title #shop-icon {
    height: 70%;
    width: 70%;
    object-fit: contain;
}

#shop-title .bi-star-fill {
    color: #fccd14 !important;
}

#pagination a {
    color: #d73d5c;
}

#follow-btn {
    --color: #DC3545;
    font-family: inherit;
    display: inline-block;
    max-width: 8em;
    width: 100%;
    height: 2.6em;
    line-height: 2.5em;
    margin: 20px;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 6px;
    font-weight: 500;
    color: var(--color);
}

#follow-btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
}

#follow-btn:hover {
    color: #fff;
}

#follow-btn:before {
    top: 100%;
    left: 100%;
    transition: all .7s;
}

#follow-btn:hover:before {
    top: -30px;
    left: -30px;
}

#follow-btn:active:before {
    background: #DC3545;
    transition: background 0s;
}

@media only screen and (min-width: 765px) {
   .schoolimg
   {
    width: 50% !important;
   }
}
@media only screen and (max-width: 575px) {
    #wishlist_item h5 {
        font-size: 16px !important;
    }

    #wishlist_item p {
        font-size: 14px !important;
    }

    #wishlist_item .btn-font-size {
        font-size: 14px !important;
    }

    #shop-title .card {
        height: 110px;
    }

    #shop-title .bg {
        height: 100px;
    }
    #schoolname{font-size: 20px; margin-top: 10px;}
}

.school-box {
    width: 68px;
    height: 70px;
    top: -35px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 4px;
    right: 50%;
    transform: translate(50%, 50%);
    line-height: 1;
}

.school-navbar::before {
    content: '';
    position: absolute;
    width: 70px;
    top: -64px;
    right: 50%;
    height: 70px;
    background: #fff;
    border-radius: 50%;
    transform: translate(50%, 50%);
    animation: pulse 1.5s infinite;
}

.school-navbar.active::before {
    content: '';
    position: absolute;
    width: 70px;
    top: -64px;
    right: 50%;
    height: 70px;
    background: #d73d5c;
    border-radius: 50%;
    transform: translate(50%, 50%);
    animation: pulse 1.5s infinite;
}




@keyframes pulse {

    0%,
    100% {
        box-shadow: 0px 4px 40px rgb(0, 0, 0, 0.25);
    }

    50% {
        box-shadow: 0 0 20px rgba(255, 0, 0, 0.6);
    }
}

@keyframes pulse2 {

    0%,
    100% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
    }

    50% {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    }
}

.subsubcat2 {

    right: 7px !important;

    top: 3px !important;

}
@media screen and (max-width:767px) {
    #shop-title .bg {
    
        font-size: 12px;
      
    }
    
}
@media screen and (max-width:575px) {
    .border-section {
        border: 2px dashed #D73D5C;
        width: 150px;
    }
    .border-section  img {
       height: 150px;
       width: 100%;
    }
    
}

.spinner-border {
    --bs-spinner-width: 4rem;
    --bs-spinner-height: 4rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.75em;
    --bs-spinner-animation-speed: 1.25s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: #000;
}
.d-flex.position-fixed.top-0.bottom-0.start-0.loading.end-0.align-items-center.justify-content-center {
    height: 100vh;
    background: rgb(0, 0, 0, 0.35);
    z-index: 10000;
}