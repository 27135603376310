/* @import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css"; */
@font-face {
    font-family: 'Poiret One';
    src: url('./assets/fonts/PoiretOne-Regular.eot');
    src: url('./assets/fonts/PoiretOne-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/PoiretOne-Regular.woff2') format('woff2'),
        url('./assets/fonts/PoiretOne-Regular.woff') format('woff'),
        url('./assets/fonts/PoiretOne-Regular.ttf') format('truetype'),
        url('./assets/fonts/PoiretOne-Regular.svg#PoiretOne-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1 {
    font-family: "Poiret One" !important;
}

body {
    font-family: "Nunito Sans" !important;

}